@charset "utf-8";

$gr: #008b45;
$or: #f47907;
$gy: #f4f6f4;

@mixin subkv($img) {
 width: 100%;
 height: 470px;
 background: url(/common/img/kv_border.png) top left repeat-y, url(/#{$img}/img/kv.jpg) center / cover no-repeat;
 @include break {
  background-size: 30px auto, cover;
  padding: 0 5%;
 }
 @include break-sp {
  height: 250px;
 }
 > .inner {
  width: 100%;
  height: 100%;
  max-width: 1200px;
  display: flex;
  align-items: center;
  margin: 0 auto;
  h1 {
   @include break-sp {
    transform: scale(0.7);
    transform-origin: left center;
   }
  }
 }
}

@mixin transition($func1: all, $func2: 0.3s) {
 transition: ($func1 $func2);
}

@mixin break($break: 1024) {
 @media screen and (max-width: $break + px) {
  @content;
 }
}
@mixin break-sp($break: 767) {
 @media screen and (max-width: $break + px) {
  @content;
 }
}

@mixin break2($break: 1025) {
 @media screen and (min-width: $break + px) {
  @content;
 }
}
@mixin break2-sp($break: 768) {
 @media screen and (min-width: $break + px) {
  @content;
 }
}

@mixin txt_rl {
 writing-mode: vertical-rl;
 -ms-writing-mode: tb-rl;
 -webkit-writing-mode: vertical-rl;
}

@mixin txt_tb {
 writing-mode: horizontal-tb;
 -ms-writing-mode: lr-tb;
 -webkit-writing-mode: horizontal-tb;
}
