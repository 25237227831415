@charset 'utf-8';
@import "mixin";
.pc {
 display: block;
 @include break {
  display: none;
 }
}
.pt {
 display: block;
 @include break-sp {
  display: none;
 }
}
.tab {
 display: none;
 @include break {
  display: block;
 }
 @include break-sp {
  display: none;
 }
}
.ts {
 display: none;
 @include break {
  display: block;
 }
}
.sp {
 display: none;
 @include break-sp {
  display: block;
 }
}
.ov {
 @include transition(opacity);
 &:hover {
  opacity: 0.8;
 }
}
.taC {
 text-align: center !important;
}
.taL {
 text-align: left !important;
}
.taR {
 text-align: right !important;
}
.btn_gr {
 font-size: 18px;
 font-weight: 600;
 display: inline-block;
 padding: 12px 15px 12px 40px;
 transition: all 0.3s;
 color: #fff;
 border: 1px solid $gr;
 border-radius: 50px;
 background: $gr;
 align-items: center;
 justify-content: center;
 @include break-sp {
  font-size: 15px;
  padding: 10px 10px 10px 30px;
 }
 &:hover {
  background: #fff;
  span {
   color: $gr;
   i {
    background: $or;
    &:after {
     background: #fff;
    }
   }
  }
 }
 span {
  display: flex;
  transition: all 0.3s;
  letter-spacing: 2px;
  align-items: center;
  i {
   margin-left: 20px;
   @include break-sp {
    margin-left: 10px;
   }
  }
 }
}
i.icon_arrow {
 position: relative;
 display: block;
 width: 35px;
 height: 35px;
 transition: all 0.3s;
 letter-spacing: 0;
 border-radius: 50px;
 background: #fff;
 @include break-sp {
  width: 30px;
  height: 30px;
 }
 &:after {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 18px;
  height: 9px;
  content: "";
  transition: all 0.3s;
  transform: translate(-50%, -50%);
  background: $or;
  clip-path: polygon(0% 35%, 60% 35%, 60% 0%, 100% 50%, 60% 100%, 60% 65%, 0% 65%);
  @include break-sp {
   width: 16px;
   height: 8px;
  }
 }
}
i.icon_arrow2 {
 background: $or;
 @extend .icon_arrow;
 &:after {
  background: #fff;
 }
}
.icon-animation {
 position: absolute;
 z-index: 30;
 top: 0;
 right: 0;
 display: none;
 width: 80px;
 height: 100%;
 @include break(1165) {
  display: block;
 }
 @include break-sp {
  width: 60px;
 }
 &.is-open {
  .top {
   transform: translateY(0px) scale(0);
  }
  .bottom {
   transform: translateY(0px) scale(0);
  }
  .middle {
   &:before {
    transform: rotate(45deg);
    background: #fff;
   }
   &:after {
    transform: rotate(-45deg);
    background: #fff;
   }
  }
 }
 span {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 35px;
  height: 4px;
  margin-left: calc(-35px / 2);
  transition: all 0.3s;
  background: $gr;
  @include break-sp {
   width: 30px;
   height: 3px;
  }
 }
 .top {
  transform: translateY(-13px) scale(1);
  @include break-sp {
   transform: translateY(-10px) scale(1);
  }
 }
 .bottom {
  transform: translateY(13px) scale(1);
  @include break-sp {
   transform: translateY(10px) scale(1);
  }
 }
 .middle {
  position: relative;
  background: none;
  &:before {
   position: absolute;
   display: block;
   width: 35px;
   height: 4px;
   content: "";
   transition: all 0.3s;
   transform: rotate(0deg);
   background: $gr;
   @include break-sp {
    width: 30px;
    height: 3px;
   }
  }
  &:after {
   position: absolute;
   display: block;
   width: 35px;
   height: 4px;
   content: "";
   transition: all 0.3s;
   transform: rotate(0deg);
   background: $gr;
   @include break-sp {
    width: 30px;
    height: 3px;
   }
  }
 }
 &:after {
  font-size: 10px;
  position: absolute;
  bottom: -15px;
  left: 1px;
  display: none;
  content: "MENU";
  white-space: nowrap;
  color: #000;
 }
}
.breadcrumbs {
 width: 100%;
 padding: 0 5%;
 ul {
  display: flex;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  flex-wrap: wrap;
  align-items: center;
  li {
   position: relative;
   &:not(:last-child) {
    margin-right: 25px;
    &:after {
     position: absolute;
     top: 50%;
     right: -13px;
     display: block;
     width: 1px;
     height: 7px;
     content: "";
     transform: translate(0, -50%);
     background: #a3a3a3;
    }
   }
   a {
    font-size: 13px;
    color: #a3a3a3;
   }
   strong {
    font-size: 13px;
    font-weight: 500;
    color: #000;
   }
  }
 }
}
header {
 position: fixed;
 z-index: 100;
 top: 0;
 left: 0;
 display: flex;
 width: 100%;
 height: 95px;
 padding-left: 30px;
 background: #fff;
 justify-content: space-between;
 align-items: center;
 @include break(1165) {
  padding: 0 5%;
 }
 @include break-sp {
  height: 60px;
 }
 .logo {
  width: 215px;
  @include break(1280) {
   width: 180px;
  }
  @include break(1165) {
   width: 150px;
  }
 }
 .hd_right {
  display: flex;
  width: calc(100% - 215px);
  height: 100%;
  padding-left: 35px;
  align-items: center;
  justify-content: flex-end;
  @include break(1280) {
   width: calc(100% - 180px);
  }
  @include break(1165) {
   position: absolute;
   top: 0;
   left: 0;
   display: none;
   width: 100%;
   height: 100vh;
   padding: 50px 10% 20px;
   background: url(/common/img/spnav_bg.gif) center bottom repeat-x $gr;
   overflow: auto;
  }
  @include break-sp {
   padding: 40px 10% 20px;
  }
  nav {
   margin-right: auto;
   height: 100%;
   @include break(1165) {
    margin: 0;
    height: auto;
   }
   > ul {
    display: flex;
    height: 100%;
    @include break(1165) {
     display: block;
    }
    > li {
     height: 100%;
     display: flex;
     align-items: center;
     position: relative;
     @include break(1165) {
      display: block;
     }
     &:not(:last-child) {
      margin-right: 35px;
      @include break(1390) {
       margin-right: 20px;
      }
      @include break(1165) {
       margin: 0 0 25px;
       padding-bottom: 25px;
       border-bottom: 1px solid #4aab7a;
      }
      @include break-sp {
       margin: 0;
       padding: 0;
      }
     }
     &.act {
      > a {
       &:after {
        left: 0;
        width: 100%;
       }
      }
     }
     > a {
      font-size: 15px;
      position: relative;
      @include break(1310) {
       font-size: 14px;
      }
      @include break(1280) {
       font-size: 12px;
      }
      @include break(1165) {
       font-size: 20px;
       color: #fff;
      }
      @include break-sp {
       font-size: 15px;
       display: block;
       padding: 15px 0;
      }
      &:hover {
       &:after {
        left: 0;
        width: 100%;
       }
      }
      &:after {
       position: absolute;
       right: 0;
       bottom: -10px;
       display: block;
       width: 0;
       height: 1px;
       content: "";
       transition: all 0.3s;
       background: $gr;
       @include break(1165) {
        display: none;
       }
      }
      i {
       font-size: 11px;
       vertical-align: middle;
       @include break(1165) {
        font-size: 15px;
       }
       @include break-sp {
        font-size: 10px;
       }
      }
     }
     ul.sub {
      display: none;
      position: absolute;
      top: 100%;
      left: 50%;
      background: #149454;
      transform: translate(-50%, 0);
      @include break(1165) {
       position: static;
       padding: 0 0 0 2em;
       background: none;
       display: block;
       margin-top: 20px;
       transform: none;
      }
      @include break-sp {
       margin-top: 0;
       padding: 0 0 15px 2em;
      }
      li {
       &:not(:last-child) {
        @include break(1165) {
         margin-bottom: 0;
         padding-bottom: 10px;
         border-bottom: none;
        }
       }
       a {
        font-size: 15px;
        white-space: nowrap;
        color: #fff;
        display: block;
        @include transition;
        @include break2(1166) {
         padding: 20px 30px;
         &:hover {
          background: $gr;
         }
        }
        @include break(1165) {
         white-space: normal;
         font-size: 18px;
        }
        @include break-sp {
         font-size: 14px;
        }
       }
      }
     }
    }
   }
  }
  .hd_tel {
   margin-right: 25px;
   pointer-events: none;
   @include break(1165) {
    display: none;
    margin: 25px 0;
   }
   @include break-sp {
    pointer-events: auto;
   }
  }
  .hd_contact {
   font-size: 20px;
   font-weight: 700;
   display: flex;
   width: 230px;
   height: 100%;
   padding: 0;
   border-radius: 0;
   align-items: center;
   justify-content: center;
   @include break(1165) {
    width: 300px;
    height: 80px;
    margin: 40px auto 0;
    background: #fff;
    color: $gr;
    border-radius: 50px;
    i {
     background: $or;
     &:after {
      background: #fff;
     }
    }
   }
   @include break-sp {
    width: 250px;
    height: 60px;
    margin-top: 10px;
    font-size: 16px;
   }
   span {
    letter-spacing: 3px;
    i {
     margin-left: 10px;
    }
   }
  }
 }
}
.ft_contact {
 width: 100%;
 padding: 0 5%;
 .bubble {
  display: none;
  text-align: center;
  margin-bottom: 70px;
  @include break-sp {
   margin-bottom: 30px;
  }
  p {
   display: inline-block;
   position: relative;
   font-size: 24px;
   font-weight: bold;
   color: $gr;
   line-height: 1.4;
   border: 2px solid $gr;
   border-radius: 50px;
   padding: 17px 60px 20px;
   background: #fff;
   @include break {
    padding: 17px 40px 20px;
    font-size: 22px;
   }
   @include break-sp {
    font-size: 16px;
   }
   &:before {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 18px 11px 0 11px;
    border-color: $gr transparent transparent transparent;
    display: block;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 100%);
    transform-origin: center top;
   }
   &:after {
    @extend :before;
    border-color: #fff transparent transparent transparent;
    transform: translate(-50%, 100%) scale(0.8);
   }
   span {
    color: $or;
   }
  }
 }
 > .inner {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  padding: 60px 0 50px;
  border-radius: 10px;
  background: url(/common/img/contact_border.gif) 0 0 repeat-x $gr;
  @include break-sp {
   padding: 40px 4% 30px;
  }
  p.ft_contact_ttl {
   font-size: 35px;
   font-weight: bold;
   line-height: 1;
   display: flex;
   flex-direction: column;
   margin-bottom: 40px;
   color: #fff;
   justify-content: center;
   align-items: center;
   @include break-sp {
    font-size: 25px;
   }
   span {
    font-size: 20px;
    margin-bottom: 15px;
    @include break-sp {
     font-size: 16px;
     margin-bottom: 10px;
    }
   }
  }
  .ft_contact_links {
   display: flex;
   justify-content: center;
   @include break-sp {
    display: block;
   }
   .ft_contact_tel {
    margin-right: 45px;
    padding-right: 45px;
    text-align: center;
    border-right: 1px solid #62b68b;
    @include break {
     margin-right: 40px;
     padding-right: 40px;
    }
    @include break-sp {
     margin: 0 0 20px;
     padding: 0 0 20px;
     border-right: none;
     border-bottom: 1px solid #62b68b;
    }
    p.ttl {
     font-size: 16px;
     margin-bottom: 20px;
     color: #fff;
     @include break-sp {
      font-size: 14px;
      margin-bottom: 10px;
     }
    }
    a {
     display: flex;
     pointer-events: none;
     align-items: center;
     @include break-sp {
      pointer-events: auto;
      justify-content: center;
     }
     i {
      font-size: 25px;
      display: flex;
      width: 55px;
      height: 55px;
      margin-right: 10px;
      color: $or;
      border-radius: 50px;
      background: #fff;
      align-items: center;
      justify-content: center;
      @include break-sp {
       font-size: 20px;
       width: 45px;
       height: 45px;
      }
     }
     p {
      font-size: 34px;
      font-weight: bold;
      line-height: 1;
      display: flex;
      flex-direction: column;
      color: #fff;
      align-items: flex-start;
      justify-content: center;
      @include break-sp {
       font-size: 26px;
      }
      span {
       font-size: 14px;
       font-weight: 500;
       margin-bottom: 10px;
       @include break-sp {
        font-size: 12px;
       }
      }
     }
    }
   }
   .ft_contact_web {
    text-align: center;
    p.ttl {
     font-size: 16px;
     margin-bottom: 20px;
     color: #fff;
     @include break-sp {
      font-size: 14px;
      margin-bottom: 10px;
     }
    }
    .btn_gr {
     color: $gr;
     border: 1px solid #fff;
     background: #fff;
     padding: 12px 20px 12px 70px;
     @include break {
      padding: 12px 20px 12px 50px;
     }
     &:hover {
      background: $gr;
      span {
       color: #fff;
      }
      i.icon_arrow {
       background: #fff;
       &:after {
        background: $or;
       }
      }
     }
     i.icon_arrow {
      margin-left: 30px;
      background: $or;
      @include break {
       margin-left: 20px;
      }
      &:after {
       background: #fff;
      }
     }
    }
   }
  }
 }
}
footer {
 position: relative;
 display: flex;
 width: 100%;
 margin-top: 120px;
 @include break-sp {
  display: block;
  margin-top: 90px;
 }
 .pagetop {
  position: absolute;
  z-index: 50;
  right: 3%;
  bottom: 30px;
  @include break {
   bottom: 15px;
  }
  @include break-sp {
   top: -30px;
   right: auto;
   bottom: auto;
   left: 50%;
   transform: translate(-50%, -0);
  }
  a {
   font-size: 14px;
   font-weight: 500;
   line-height: 1;
   position: relative;
   display: flex;
   color: #9d9d9d;
   @include txt_rl;
   align-items: center;
   @include break-sp {
    font-size: 12px;
    flex-direction: column;
    @include txt_tb;
   }
   span {
    position: relative;
    display: block;
    overflow: hidden;
    width: 1px;
    height: 135px;
    margin-bottom: 15px;
    background: #5d5d5d;
    @include break {
     height: 70px;
    }
    @include break-sp {
     height: 50px;
     margin-bottom: 5px;
    }
    &:after {
     position: absolute;
     bottom: 0;
     left: 0;
     display: block;
     width: 1px;
     height: 20px;
     content: "";
     animation: gotop forwards 2s infinite cubic-bezier(0.07, 1.05, 0.3, 1);
     background: $or;
     @include break-sp {
      animation: gotopsp forwards 1.5s infinite cubic-bezier(0.07, 1.05, 0.3, 1);
     }
    }
   }
   @keyframes gotop {
    0% {
     transform: translateY(20px);
    }
    100% {
     transform: translateY(-135px);
    }
   }
   @keyframes gotopsp {
    0% {
     transform: translateY(20px);
    }
    100% {
     transform: translateY(-50px);
    }
   }
  }
 }
 .ft_logo {
  display: flex;
  flex-direction: column;
  width: 35%;
  padding: 0 20px;
  text-align: center;
  background: #101010;
  align-items: center;
  justify-content: center;
  order: -1;
  @include break-sp {
   display: block;
   width: 100%;
   padding: 40px 5%;
   text-align: left;
  }
  .logo {
   text-align: left;
   @include break-sp {
    width: 200px;
   }
   img {
    max-width: 315px;
   }
  }
  p.copy {
   margin-top: 25px;
   @include break-sp {
    margin-top: 15px;
   }
   small {
    font-size: 12px;
    color: #fff;
    @include break {
     font-size: 10px;
    }
   }
  }
 }
 .ft_nav {
  width: 65%;
  padding: 55px 0 70px 8%;
  background: #272727;
  @include break {
   display: flex;
   padding: 50px 0;
   justify-content: center;
  }
  @include break-sp {
   display: block;
   width: 100%;
   padding: 60px 5% 20px;
  }
  ul {
   display: grid;
   grid-auto-flow: column;
   grid-auto-columns: max-content;
   grid-template-rows: auto auto auto auto;
   column-gap: 70px;
   row-gap: 25px;
   @include break {
    column-gap: 30px;
   }
   @include break-sp {
    display: block;
    padding-top: 20px;
    border-top: 1px solid #323232;
   }
   li {
    @include break-sp {
     &:not(:last-child) {
      margin-bottom: 15px;
     }
    }
    a {
     color: #fff;
     font-size: 14px;
     @include break-sp {
      font-size: 12px;
     }
     i {
      font-size: 10px;
      vertical-align: middle;
     }
    }
   }
  }
 }
}
.service_links {
 margin-bottom: 110px;
 @include break {
  padding: 0 5%;
 }
 @include break-sp {
  margin-bottom: 50px;
 }
 .inner {
  h2 {
   font-size: 30px;
   text-align: center;
   font-weight: bold;
   color: $gr;
   margin-bottom: 50px;
   @include break-sp {
    font-size: 20px;
    margin-bottom: 30px;
   }
  }
  ul {
   display: flex;
   @include break {
    flex-wrap: wrap;
    justify-content: space-between;
   }
   justify-content: center;
   li {
    &:not(:last-child) {
     @include break2 {
      border-right: 1px solid #ececec;
     }
    }
    @include break {
     width: 47%;
     border-bottom: 1px solid #ececec;
     &:nth-child(n + 3) {
      margin-top: 40px;
     }
    }
    @include break-sp {
     width: 48%;
    }
    a {
     padding: 0 50px;
     text-align: center;
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: center;
     @include break {
      padding: 0 0 20px;
     }
     @include break-sp {
      height: 100%;
     }
     &:hover {
      i {
       background: #fff;
       &:after {
        background: $or;
       }
      }
     }
    }
    .icon {
     display: flex;
     position: relative;
     height: 66px;
     justify-content: center;
     align-items: flex-end;
     margin-bottom: 10px;
     @include break-sp {
      height: auto;
     }
     &.icon_r {
      &:after {
       right: -20px;
      }
     }
     &:after {
      display: block;
      position: absolute;
      top: -10px;
      right: -10px;
      width: 47px;
      height: 47px;
      border-radius: 50px;
      background: $gy;
      content: "";
     }
     img {
      position: relative;
      z-index: 2;
      @include break-sp {
       transform: scale(0.9);
       transform-origin: center bottom;
      }
     }
    }
    p {
     font-size: 20px;
     font-weight: bold;
     margin-bottom: 15px;
     @include break-sp {
      font-size: 16px;
     }
    }
    i {
     margin-top: auto;
     border: 1px solid $or;
    }
   }
  }
 }
}
.notfound {
 width: 100%;
 text-align: center;
 margin-bottom: 150px;
 padding: 100px 5% 0;
 .img_404 {
  margin-bottom: 60px;
 }
 h1 {
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  line-height: 1;
  font-size: 55px;
  color: $gr;
  display: flex;
  flex-direction: column;
  span {
   margin-top: 15px;
   font-family: "Noto Sans JP", sans-serif;
   font-size: 29px;
   color: #808080;
  }
 }
 p {
  margin: 35px 0 60px;
  font-size: 18px;
  color: #808080;
 }
 a.btn_gr {
  padding: 12px 15px 12px 80px;
  i {
   margin-left: 40px;
  }
 }
}
