.pc {
  display: block;
}
@media screen and (max-width: 1024px) {
  .pc {
    display: none;
  }
}

.pt {
  display: block;
}
@media screen and (max-width: 767px) {
  .pt {
    display: none;
  }
}

.tab {
  display: none;
}
@media screen and (max-width: 1024px) {
  .tab {
    display: block;
  }
}
@media screen and (max-width: 767px) {
  .tab {
    display: none;
  }
}

.ts {
  display: none;
}
@media screen and (max-width: 1024px) {
  .ts {
    display: block;
  }
}

.sp {
  display: none;
}
@media screen and (max-width: 767px) {
  .sp {
    display: block;
  }
}

.ov {
  transition: opacity 0.3s;
}
.ov:hover {
  opacity: 0.8;
}

.taC {
  text-align: center !important;
}

.taL {
  text-align: left !important;
}

.taR {
  text-align: right !important;
}

.btn_gr {
  font-size: 18px;
  font-weight: 600;
  display: inline-block;
  padding: 12px 15px 12px 40px;
  transition: all 0.3s;
  color: #fff;
  border: 1px solid #008b45;
  border-radius: 50px;
  background: #008b45;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 767px) {
  .btn_gr {
    font-size: 15px;
    padding: 10px 10px 10px 30px;
  }
}
.btn_gr:hover {
  background: #fff;
}
.btn_gr:hover span {
  color: #008b45;
}
.btn_gr:hover span i {
  background: #f47907;
}
.btn_gr:hover span i:after {
  background: #fff;
}
.btn_gr span {
  display: flex;
  transition: all 0.3s;
  letter-spacing: 2px;
  align-items: center;
}
.btn_gr span i {
  margin-left: 20px;
}
@media screen and (max-width: 767px) {
  .btn_gr span i {
    margin-left: 10px;
  }
}

i.icon_arrow, i.icon_arrow2 {
  position: relative;
  display: block;
  width: 35px;
  height: 35px;
  transition: all 0.3s;
  letter-spacing: 0;
  border-radius: 50px;
  background: #fff;
}
@media screen and (max-width: 767px) {
  i.icon_arrow, i.icon_arrow2 {
    width: 30px;
    height: 30px;
  }
}
i.icon_arrow:after, i.icon_arrow2:after {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 18px;
  height: 9px;
  content: "";
  transition: all 0.3s;
  transform: translate(-50%, -50%);
  background: #f47907;
  clip-path: polygon(0% 35%, 60% 35%, 60% 0%, 100% 50%, 60% 100%, 60% 65%, 0% 65%);
}
@media screen and (max-width: 767px) {
  i.icon_arrow:after, i.icon_arrow2:after {
    width: 16px;
    height: 8px;
  }
}

i.icon_arrow2 {
  background: #f47907;
}
i.icon_arrow2:after {
  background: #fff;
}

.icon-animation {
  position: absolute;
  z-index: 30;
  top: 0;
  right: 0;
  display: none;
  width: 80px;
  height: 100%;
}
@media screen and (max-width: 1165px) {
  .icon-animation {
    display: block;
  }
}
@media screen and (max-width: 767px) {
  .icon-animation {
    width: 60px;
  }
}
.icon-animation.is-open .top {
  transform: translateY(0px) scale(0);
}
.icon-animation.is-open .bottom {
  transform: translateY(0px) scale(0);
}
.icon-animation.is-open .middle:before, .icon-animation.is-open .ft_contact .bubble p.middle:after, .ft_contact .bubble .icon-animation.is-open p.middle:after {
  transform: rotate(45deg);
  background: #fff;
}
.icon-animation.is-open .middle:after {
  transform: rotate(-45deg);
  background: #fff;
}
.icon-animation span {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 35px;
  height: 4px;
  margin-left: -17.5px;
  transition: all 0.3s;
  background: #008b45;
}
@media screen and (max-width: 767px) {
  .icon-animation span {
    width: 30px;
    height: 3px;
  }
}
.icon-animation .top {
  transform: translateY(-13px) scale(1);
}
@media screen and (max-width: 767px) {
  .icon-animation .top {
    transform: translateY(-10px) scale(1);
  }
}
.icon-animation .bottom {
  transform: translateY(13px) scale(1);
}
@media screen and (max-width: 767px) {
  .icon-animation .bottom {
    transform: translateY(10px) scale(1);
  }
}
.icon-animation .middle {
  position: relative;
  background: none;
}
.icon-animation .middle:before, .icon-animation .ft_contact .bubble p.middle:after, .ft_contact .bubble .icon-animation p.middle:after {
  position: absolute;
  display: block;
  width: 35px;
  height: 4px;
  content: "";
  transition: all 0.3s;
  transform: rotate(0deg);
  background: #008b45;
}
@media screen and (max-width: 767px) {
  .icon-animation .middle:before, .icon-animation .ft_contact .bubble p.middle:after, .ft_contact .bubble .icon-animation p.middle:after {
    width: 30px;
    height: 3px;
  }
}
.icon-animation .middle:after {
  position: absolute;
  display: block;
  width: 35px;
  height: 4px;
  content: "";
  transition: all 0.3s;
  transform: rotate(0deg);
  background: #008b45;
}
@media screen and (max-width: 767px) {
  .icon-animation .middle:after {
    width: 30px;
    height: 3px;
  }
}
.icon-animation:after {
  font-size: 10px;
  position: absolute;
  bottom: -15px;
  left: 1px;
  display: none;
  content: "MENU";
  white-space: nowrap;
  color: #000;
}

.breadcrumbs {
  width: 100%;
  padding: 0 5%;
}
.breadcrumbs ul {
  display: flex;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  flex-wrap: wrap;
  align-items: center;
}
.breadcrumbs ul li {
  position: relative;
}
.breadcrumbs ul li:not(:last-child) {
  margin-right: 25px;
}
.breadcrumbs ul li:not(:last-child):after {
  position: absolute;
  top: 50%;
  right: -13px;
  display: block;
  width: 1px;
  height: 7px;
  content: "";
  transform: translate(0, -50%);
  background: #a3a3a3;
}
.breadcrumbs ul li a {
  font-size: 13px;
  color: #a3a3a3;
}
.breadcrumbs ul li strong {
  font-size: 13px;
  font-weight: 500;
  color: #000;
}

header {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 95px;
  padding-left: 30px;
  background: #fff;
  justify-content: space-between;
  align-items: center;
}
@media screen and (max-width: 1165px) {
  header {
    padding: 0 5%;
  }
}
@media screen and (max-width: 767px) {
  header {
    height: 60px;
  }
}
header .logo {
  width: 215px;
}
@media screen and (max-width: 1280px) {
  header .logo {
    width: 180px;
  }
}
@media screen and (max-width: 1165px) {
  header .logo {
    width: 150px;
  }
}
header .hd_right {
  display: flex;
  width: calc(100% - 215px);
  height: 100%;
  padding-left: 35px;
  align-items: center;
  justify-content: flex-end;
}
@media screen and (max-width: 1280px) {
  header .hd_right {
    width: calc(100% - 180px);
  }
}
@media screen and (max-width: 1165px) {
  header .hd_right {
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    width: 100%;
    height: 100vh;
    padding: 50px 10% 20px;
    background: url(/common/img/spnav_bg.gif) center bottom repeat-x #008b45;
    overflow: auto;
  }
}
@media screen and (max-width: 767px) {
  header .hd_right {
    padding: 40px 10% 20px;
  }
}
header .hd_right nav {
  margin-right: auto;
  height: 100%;
}
@media screen and (max-width: 1165px) {
  header .hd_right nav {
    margin: 0;
    height: auto;
  }
}
header .hd_right nav > ul {
  display: flex;
  height: 100%;
}
@media screen and (max-width: 1165px) {
  header .hd_right nav > ul {
    display: block;
  }
}
header .hd_right nav > ul > li {
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
}
@media screen and (max-width: 1165px) {
  header .hd_right nav > ul > li {
    display: block;
  }
}
header .hd_right nav > ul > li:not(:last-child) {
  margin-right: 35px;
}
@media screen and (max-width: 1390px) {
  header .hd_right nav > ul > li:not(:last-child) {
    margin-right: 20px;
  }
}
@media screen and (max-width: 1165px) {
  header .hd_right nav > ul > li:not(:last-child) {
    margin: 0 0 25px;
    padding-bottom: 25px;
    border-bottom: 1px solid #4aab7a;
  }
}
@media screen and (max-width: 767px) {
  header .hd_right nav > ul > li:not(:last-child) {
    margin: 0;
    padding: 0;
  }
}
header .hd_right nav > ul > li.act > a:after {
  left: 0;
  width: 100%;
}
header .hd_right nav > ul > li > a {
  font-size: 15px;
  position: relative;
}
@media screen and (max-width: 1310px) {
  header .hd_right nav > ul > li > a {
    font-size: 14px;
  }
}
@media screen and (max-width: 1280px) {
  header .hd_right nav > ul > li > a {
    font-size: 12px;
  }
}
@media screen and (max-width: 1165px) {
  header .hd_right nav > ul > li > a {
    font-size: 20px;
    color: #fff;
  }
}
@media screen and (max-width: 767px) {
  header .hd_right nav > ul > li > a {
    font-size: 15px;
    display: block;
    padding: 15px 0;
  }
}
header .hd_right nav > ul > li > a:hover:after {
  left: 0;
  width: 100%;
}
header .hd_right nav > ul > li > a:after {
  position: absolute;
  right: 0;
  bottom: -10px;
  display: block;
  width: 0;
  height: 1px;
  content: "";
  transition: all 0.3s;
  background: #008b45;
}
@media screen and (max-width: 1165px) {
  header .hd_right nav > ul > li > a:after {
    display: none;
  }
}
header .hd_right nav > ul > li > a i {
  font-size: 11px;
  vertical-align: middle;
}
@media screen and (max-width: 1165px) {
  header .hd_right nav > ul > li > a i {
    font-size: 15px;
  }
}
@media screen and (max-width: 767px) {
  header .hd_right nav > ul > li > a i {
    font-size: 10px;
  }
}
header .hd_right nav > ul > li ul.sub {
  display: none;
  position: absolute;
  top: 100%;
  left: 50%;
  background: #149454;
  transform: translate(-50%, 0);
}
@media screen and (max-width: 1165px) {
  header .hd_right nav > ul > li ul.sub {
    position: static;
    padding: 0 0 0 2em;
    background: none;
    display: block;
    margin-top: 20px;
    transform: none;
  }
}
@media screen and (max-width: 767px) {
  header .hd_right nav > ul > li ul.sub {
    margin-top: 0;
    padding: 0 0 15px 2em;
  }
}
@media screen and (max-width: 1165px) {
  header .hd_right nav > ul > li ul.sub li:not(:last-child) {
    margin-bottom: 0;
    padding-bottom: 10px;
    border-bottom: none;
  }
}
header .hd_right nav > ul > li ul.sub li a {
  font-size: 15px;
  white-space: nowrap;
  color: #fff;
  display: block;
  transition: all 0.3s;
}
@media screen and (min-width: 1166px) {
  header .hd_right nav > ul > li ul.sub li a {
    padding: 20px 30px;
  }
  header .hd_right nav > ul > li ul.sub li a:hover {
    background: #008b45;
  }
}
@media screen and (max-width: 1165px) {
  header .hd_right nav > ul > li ul.sub li a {
    white-space: normal;
    font-size: 18px;
  }
}
@media screen and (max-width: 767px) {
  header .hd_right nav > ul > li ul.sub li a {
    font-size: 14px;
  }
}
header .hd_right .hd_tel {
  margin-right: 25px;
  pointer-events: none;
}
@media screen and (max-width: 1165px) {
  header .hd_right .hd_tel {
    display: none;
    margin: 25px 0;
  }
}
@media screen and (max-width: 767px) {
  header .hd_right .hd_tel {
    pointer-events: auto;
  }
}
header .hd_right .hd_contact {
  font-size: 20px;
  font-weight: 700;
  display: flex;
  width: 230px;
  height: 100%;
  padding: 0;
  border-radius: 0;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 1165px) {
  header .hd_right .hd_contact {
    width: 300px;
    height: 80px;
    margin: 40px auto 0;
    background: #fff;
    color: #008b45;
    border-radius: 50px;
  }
  header .hd_right .hd_contact i {
    background: #f47907;
  }
  header .hd_right .hd_contact i:after {
    background: #fff;
  }
}
@media screen and (max-width: 767px) {
  header .hd_right .hd_contact {
    width: 250px;
    height: 60px;
    margin-top: 10px;
    font-size: 16px;
  }
}
header .hd_right .hd_contact span {
  letter-spacing: 3px;
}
header .hd_right .hd_contact span i {
  margin-left: 10px;
}

.ft_contact {
  width: 100%;
  padding: 0 5%;
}
.ft_contact .bubble {
  display: none;
  text-align: center;
  margin-bottom: 70px;
}
@media screen and (max-width: 767px) {
  .ft_contact .bubble {
    margin-bottom: 30px;
  }
}
.ft_contact .bubble p {
  display: inline-block;
  position: relative;
  font-size: 24px;
  font-weight: bold;
  color: #008b45;
  line-height: 1.4;
  border: 2px solid #008b45;
  border-radius: 50px;
  padding: 17px 60px 20px;
  background: #fff;
}
@media screen and (max-width: 1024px) {
  .ft_contact .bubble p {
    padding: 17px 40px 20px;
    font-size: 22px;
  }
}
@media screen and (max-width: 767px) {
  .ft_contact .bubble p {
    font-size: 16px;
  }
}
.ft_contact .bubble p:before, .ft_contact .bubble p:after {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 18px 11px 0 11px;
  border-color: #008b45 transparent transparent transparent;
  display: block;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 100%);
  transform-origin: center top;
}
.ft_contact .bubble p:after {
  border-color: #fff transparent transparent transparent;
  transform: translate(-50%, 100%) scale(0.8);
}
.ft_contact .bubble p span {
  color: #f47907;
}
.ft_contact > .inner {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  padding: 60px 0 50px;
  border-radius: 10px;
  background: url(/common/img/contact_border.gif) 0 0 repeat-x #008b45;
}
@media screen and (max-width: 767px) {
  .ft_contact > .inner {
    padding: 40px 4% 30px;
  }
}
.ft_contact > .inner p.ft_contact_ttl {
  font-size: 35px;
  font-weight: bold;
  line-height: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  color: #fff;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .ft_contact > .inner p.ft_contact_ttl {
    font-size: 25px;
  }
}
.ft_contact > .inner p.ft_contact_ttl span {
  font-size: 20px;
  margin-bottom: 15px;
}
@media screen and (max-width: 767px) {
  .ft_contact > .inner p.ft_contact_ttl span {
    font-size: 16px;
    margin-bottom: 10px;
  }
}
.ft_contact > .inner .ft_contact_links {
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 767px) {
  .ft_contact > .inner .ft_contact_links {
    display: block;
  }
}
.ft_contact > .inner .ft_contact_links .ft_contact_tel {
  margin-right: 45px;
  padding-right: 45px;
  text-align: center;
  border-right: 1px solid #62b68b;
}
@media screen and (max-width: 1024px) {
  .ft_contact > .inner .ft_contact_links .ft_contact_tel {
    margin-right: 40px;
    padding-right: 40px;
  }
}
@media screen and (max-width: 767px) {
  .ft_contact > .inner .ft_contact_links .ft_contact_tel {
    margin: 0 0 20px;
    padding: 0 0 20px;
    border-right: none;
    border-bottom: 1px solid #62b68b;
  }
}
.ft_contact > .inner .ft_contact_links .ft_contact_tel p.ttl {
  font-size: 16px;
  margin-bottom: 20px;
  color: #fff;
}
@media screen and (max-width: 767px) {
  .ft_contact > .inner .ft_contact_links .ft_contact_tel p.ttl {
    font-size: 14px;
    margin-bottom: 10px;
  }
}
.ft_contact > .inner .ft_contact_links .ft_contact_tel a {
  display: flex;
  pointer-events: none;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .ft_contact > .inner .ft_contact_links .ft_contact_tel a {
    pointer-events: auto;
    justify-content: center;
  }
}
.ft_contact > .inner .ft_contact_links .ft_contact_tel a i {
  font-size: 25px;
  display: flex;
  width: 55px;
  height: 55px;
  margin-right: 10px;
  color: #f47907;
  border-radius: 50px;
  background: #fff;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 767px) {
  .ft_contact > .inner .ft_contact_links .ft_contact_tel a i {
    font-size: 20px;
    width: 45px;
    height: 45px;
  }
}
.ft_contact > .inner .ft_contact_links .ft_contact_tel a p {
  font-size: 34px;
  font-weight: bold;
  line-height: 1;
  display: flex;
  flex-direction: column;
  color: #fff;
  align-items: flex-start;
  justify-content: center;
}
@media screen and (max-width: 767px) {
  .ft_contact > .inner .ft_contact_links .ft_contact_tel a p {
    font-size: 26px;
  }
}
.ft_contact > .inner .ft_contact_links .ft_contact_tel a p span {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
}
@media screen and (max-width: 767px) {
  .ft_contact > .inner .ft_contact_links .ft_contact_tel a p span {
    font-size: 12px;
  }
}
.ft_contact > .inner .ft_contact_links .ft_contact_web {
  text-align: center;
}
.ft_contact > .inner .ft_contact_links .ft_contact_web p.ttl {
  font-size: 16px;
  margin-bottom: 20px;
  color: #fff;
}
@media screen and (max-width: 767px) {
  .ft_contact > .inner .ft_contact_links .ft_contact_web p.ttl {
    font-size: 14px;
    margin-bottom: 10px;
  }
}
.ft_contact > .inner .ft_contact_links .ft_contact_web .btn_gr {
  color: #008b45;
  border: 1px solid #fff;
  background: #fff;
  padding: 12px 20px 12px 70px;
}
@media screen and (max-width: 1024px) {
  .ft_contact > .inner .ft_contact_links .ft_contact_web .btn_gr {
    padding: 12px 20px 12px 50px;
  }
}
.ft_contact > .inner .ft_contact_links .ft_contact_web .btn_gr:hover {
  background: #008b45;
}
.ft_contact > .inner .ft_contact_links .ft_contact_web .btn_gr:hover span {
  color: #fff;
}
.ft_contact > .inner .ft_contact_links .ft_contact_web .btn_gr:hover i.icon_arrow, .ft_contact > .inner .ft_contact_links .ft_contact_web .btn_gr:hover i.icon_arrow2 {
  background: #fff;
}
.ft_contact > .inner .ft_contact_links .ft_contact_web .btn_gr:hover i.icon_arrow:after, .ft_contact > .inner .ft_contact_links .ft_contact_web .btn_gr:hover i.icon_arrow2:after {
  background: #f47907;
}
.ft_contact > .inner .ft_contact_links .ft_contact_web .btn_gr i.icon_arrow, .ft_contact > .inner .ft_contact_links .ft_contact_web .btn_gr i.icon_arrow2 {
  margin-left: 30px;
  background: #f47907;
}
@media screen and (max-width: 1024px) {
  .ft_contact > .inner .ft_contact_links .ft_contact_web .btn_gr i.icon_arrow, .ft_contact > .inner .ft_contact_links .ft_contact_web .btn_gr i.icon_arrow2 {
    margin-left: 20px;
  }
}
.ft_contact > .inner .ft_contact_links .ft_contact_web .btn_gr i.icon_arrow:after, .ft_contact > .inner .ft_contact_links .ft_contact_web .btn_gr i.icon_arrow2:after {
  background: #fff;
}

footer {
  position: relative;
  display: flex;
  width: 100%;
  margin-top: 120px;
}
@media screen and (max-width: 767px) {
  footer {
    display: block;
    margin-top: 90px;
  }
}
footer .pagetop {
  position: absolute;
  z-index: 50;
  right: 3%;
  bottom: 30px;
}
@media screen and (max-width: 1024px) {
  footer .pagetop {
    bottom: 15px;
  }
}
@media screen and (max-width: 767px) {
  footer .pagetop {
    top: -30px;
    right: auto;
    bottom: auto;
    left: 50%;
    transform: translate(-50%, 0);
  }
}
footer .pagetop a {
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  position: relative;
  display: flex;
  color: #9d9d9d;
  writing-mode: vertical-rl;
  -ms-writing-mode: tb-rl;
  -webkit-writing-mode: vertical-rl;
  align-items: center;
}
@media screen and (max-width: 767px) {
  footer .pagetop a {
    font-size: 12px;
    flex-direction: column;
    writing-mode: horizontal-tb;
    -ms-writing-mode: lr-tb;
    -webkit-writing-mode: horizontal-tb;
  }
}
footer .pagetop a span {
  position: relative;
  display: block;
  overflow: hidden;
  width: 1px;
  height: 135px;
  margin-bottom: 15px;
  background: #5d5d5d;
}
@media screen and (max-width: 1024px) {
  footer .pagetop a span {
    height: 70px;
  }
}
@media screen and (max-width: 767px) {
  footer .pagetop a span {
    height: 50px;
    margin-bottom: 5px;
  }
}
footer .pagetop a span:after {
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  width: 1px;
  height: 20px;
  content: "";
  animation: gotop forwards 2s infinite cubic-bezier(0.07, 1.05, 0.3, 1);
  background: #f47907;
}
@media screen and (max-width: 767px) {
  footer .pagetop a span:after {
    animation: gotopsp forwards 1.5s infinite cubic-bezier(0.07, 1.05, 0.3, 1);
  }
}
@keyframes gotop {
  0% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(-135px);
  }
}
@keyframes gotopsp {
  0% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(-50px);
  }
}
footer .ft_logo {
  display: flex;
  flex-direction: column;
  width: 35%;
  padding: 0 20px;
  text-align: center;
  background: #101010;
  align-items: center;
  justify-content: center;
  order: -1;
}
@media screen and (max-width: 767px) {
  footer .ft_logo {
    display: block;
    width: 100%;
    padding: 40px 5%;
    text-align: left;
  }
}
footer .ft_logo .logo {
  text-align: left;
}
@media screen and (max-width: 767px) {
  footer .ft_logo .logo {
    width: 200px;
  }
}
footer .ft_logo .logo img {
  max-width: 315px;
}
footer .ft_logo p.copy {
  margin-top: 25px;
}
@media screen and (max-width: 767px) {
  footer .ft_logo p.copy {
    margin-top: 15px;
  }
}
footer .ft_logo p.copy small {
  font-size: 12px;
  color: #fff;
}
@media screen and (max-width: 1024px) {
  footer .ft_logo p.copy small {
    font-size: 10px;
  }
}
footer .ft_nav {
  width: 65%;
  padding: 55px 0 70px 8%;
  background: #272727;
}
@media screen and (max-width: 1024px) {
  footer .ft_nav {
    display: flex;
    padding: 50px 0;
    justify-content: center;
  }
}
@media screen and (max-width: 767px) {
  footer .ft_nav {
    display: block;
    width: 100%;
    padding: 60px 5% 20px;
  }
}
footer .ft_nav ul {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  grid-template-rows: auto auto auto auto;
  column-gap: 70px;
  row-gap: 25px;
}
@media screen and (max-width: 1024px) {
  footer .ft_nav ul {
    column-gap: 30px;
  }
}
@media screen and (max-width: 767px) {
  footer .ft_nav ul {
    display: block;
    padding-top: 20px;
    border-top: 1px solid #323232;
  }
}
@media screen and (max-width: 767px) {
  footer .ft_nav ul li:not(:last-child) {
    margin-bottom: 15px;
  }
}
footer .ft_nav ul li a {
  color: #fff;
  font-size: 14px;
}
@media screen and (max-width: 767px) {
  footer .ft_nav ul li a {
    font-size: 12px;
  }
}
footer .ft_nav ul li a i {
  font-size: 10px;
  vertical-align: middle;
}

.service_links {
  margin-bottom: 110px;
}
@media screen and (max-width: 1024px) {
  .service_links {
    padding: 0 5%;
  }
}
@media screen and (max-width: 767px) {
  .service_links {
    margin-bottom: 50px;
  }
}
.service_links .inner h2 {
  font-size: 30px;
  text-align: center;
  font-weight: bold;
  color: #008b45;
  margin-bottom: 50px;
}
@media screen and (max-width: 767px) {
  .service_links .inner h2 {
    font-size: 20px;
    margin-bottom: 30px;
  }
}
.service_links .inner ul {
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 1024px) {
  .service_links .inner ul {
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
@media screen and (min-width: 1025px) {
  .service_links .inner ul li:not(:last-child) {
    border-right: 1px solid #ececec;
  }
}
@media screen and (max-width: 1024px) {
  .service_links .inner ul li {
    width: 47%;
    border-bottom: 1px solid #ececec;
  }
  .service_links .inner ul li:nth-child(n+3) {
    margin-top: 40px;
  }
}
@media screen and (max-width: 767px) {
  .service_links .inner ul li {
    width: 48%;
  }
}
.service_links .inner ul li a {
  padding: 0 50px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 1024px) {
  .service_links .inner ul li a {
    padding: 0 0 20px;
  }
}
@media screen and (max-width: 767px) {
  .service_links .inner ul li a {
    height: 100%;
  }
}
.service_links .inner ul li a:hover i {
  background: #fff;
}
.service_links .inner ul li a:hover i:after {
  background: #f47907;
}
.service_links .inner ul li .icon {
  display: flex;
  position: relative;
  height: 66px;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 10px;
}
@media screen and (max-width: 767px) {
  .service_links .inner ul li .icon {
    height: auto;
  }
}
.service_links .inner ul li .icon.icon_r:after {
  right: -20px;
}
.service_links .inner ul li .icon:after {
  display: block;
  position: absolute;
  top: -10px;
  right: -10px;
  width: 47px;
  height: 47px;
  border-radius: 50px;
  background: #f4f6f4;
  content: "";
}
.service_links .inner ul li .icon img {
  position: relative;
  z-index: 2;
}
@media screen and (max-width: 767px) {
  .service_links .inner ul li .icon img {
    transform: scale(0.9);
    transform-origin: center bottom;
  }
}
.service_links .inner ul li p {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 15px;
}
@media screen and (max-width: 767px) {
  .service_links .inner ul li p {
    font-size: 16px;
  }
}
.service_links .inner ul li i {
  margin-top: auto;
  border: 1px solid #f47907;
}

.notfound {
  width: 100%;
  text-align: center;
  margin-bottom: 150px;
  padding: 100px 5% 0;
}
.notfound .img_404 {
  margin-bottom: 60px;
}
.notfound h1 {
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  line-height: 1;
  font-size: 55px;
  color: #008b45;
  display: flex;
  flex-direction: column;
}
.notfound h1 span {
  margin-top: 15px;
  font-family: "Noto Sans JP", sans-serif;
  font-size: 29px;
  color: #808080;
}
.notfound p {
  margin: 35px 0 60px;
  font-size: 18px;
  color: #808080;
}
.notfound a.btn_gr {
  padding: 12px 15px 12px 80px;
}
.notfound a.btn_gr i {
  margin-left: 40px;
}